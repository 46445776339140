<template>
  <section class="section">
    <form class="container-admin" @submit="handleSubmit">
      <b-loading :active.sync="isLoading" />
      <div class="columns is-multiline">
        <div class="column">
          <h3 class="title is-3">
            <router-link to="/admin/caixa" class="has-text-grey">
              Caixa
            </router-link>
            >
            <span class="title is-3 has-text-primary">
              Adicionar
            </span>
          </h3>
        </div>
        <div class="column is-1" style="min-width: fit-content">
          <b-field label="Saldo Atual" class="column mb-0" />
          <p style="margin-left: 40px; margin-top: -10px;">R$ {{ getSaldo() }}</p>
        </div>
      </div>
      <div class="columns is-multiline">
        <b-field label="Conta Bancária" class="column is-half mb-0">
          <b-select v-model="form.contaId" placeholder="Selecione" expanded>
            <option v-for="conta in contaBancaria" :key="conta.id" :value="conta.id">
              {{ conta.nome }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Centro de Custo" class="column is-half mb-0">
          <b-select v-model="form.centroCustoId" placeholder="Selecione" expanded>
            <option v-for="centroCusto in centroCusto" :key="centroCusto.id" :value="centroCusto.id">
              {{ centroCusto.nome }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Referência" class="column is-one-third">
          <b-input type="text" v-model="form.referencia" placeholder="Digite" />
        </b-field>
        <b-field label="Descrição" class="column is-one-third">
          <b-input type="text" v-model="form.descricao" placeholder="Digite" />
        </b-field>
        <b-field class="column is-one-third" label="Valor">
          <b-input v-model="form.valor" placeholder="0,00" type="number" step="0.01" required />
        </b-field>
      </div>
      <b-button type="is-primary" tag="button" native-type="submit" label="Salvar" expanded />
    </form>
  </section>
</template>

<script>
import financeiroCentroCustoService from '@/services/financeiroCentroCustoService';
import financeiroContaService from '@/services/financeiroContaService';
import financeiroLancamentoService from '@/services/financeiroLancamentoService';

export default {
  name: 'Adicionar',
  props: {},
  data() {
    return {
      isLoading: false,
      salvando: false,
      form: {},
      contaBancaria: [],
      centroCusto: [],
    };
  },
  computed: {},
  async created() {
    this.getContaBanco();
    this.getCentroCusto();
  },
  methods: {
    async getContaBanco() {
      return financeiroContaService.get().then(({ data }) => {
        this.contaBancaria = data;
      });
    },
    async getCentroCusto() {
      return financeiroCentroCustoService.get().then(({ data }) => {
        this.centroCusto = data;
      });
    },

    getSaldo() {
      if (this.form.contaId) {
        const dadosBancario = this.contaBancaria.find(c => c.id === this.form.contaId);
        return dadosBancario.saldo;
      }
      return '00.00';
    },
    async handleSubmit(event) {
      event.preventDefault();

      const bodyData = { ...this.form };
      bodyData.valor = parseFloat(bodyData.valor);

      this.salvando = true;

      try {
        this.isLoading = true;

        await financeiroLancamentoService.post(this.form).then(() => {
          this.$buefy.snackbar.open('Lançamento salvo com sucesso');
          this.$router.push('/admin/caixa');
        });
      } catch {
        this.$buefy.snackbar.open({
          message: `Erro ao salvar lançamento`,
          type: 'is-danger',
        });
      } finally {
        this.salvando = false;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.upload-label {
  width: 100%;
  height: 100%;
  background-color: rgb(224, 224, 224);
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed gray;
  border-radius: 8px;
}
.upload-label div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-label img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
