import httpAuthClient from './httpAuthClient';

const url = `financeiroCentroCusto`;

export default {
  getById(id) {
    return httpAuthClient.get(`${url}/${id}`);
  },
  get() {
    return httpAuthClient.get(`/${url}`);
  },
};
