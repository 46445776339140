import httpAuthClient from './httpAuthClient';

const url = `financeiroLancamento`;

export default {
  getById(id) {
    return httpAuthClient.get(`/${url}/${id}`);
  },
  get() {
    return httpAuthClient.get(`/${url}`);
  },
  post(lancamento) {
    return httpAuthClient.post(`/${url}`, lancamento);
  },
  getByRangeData({ dataI, dataF, contaId }) {
    return httpAuthClient.get(`/${url}/GetByRangeData?contaId=${contaId}&dataI=${dataI}&dataF=${dataF}`);
  },
};
